import React from 'react';
import { useParams } from 'react-router-dom';
import DynamicBitratePlayer from '../../DynamicBitratePlayer/DynamicBitratePlayer';

function MovieDisplay() {
  const { movieId } = useParams();
  const decodedMovieId = decodeURIComponent(movieId); // Decode movie ID for display

  // URL to the HLS master file (adjusted to point to the NAS-mounted directory)
  const movieUrl = `/Movies/${encodeURIComponent(decodedMovieId)}/master.m3u8`;

  return (
    <div>
      <h1>Now Playing: {decodedMovieId}</h1>
      <DynamicBitratePlayer
        videoSource={movieUrl}         // HLS master playlist
      />
    </div>
  );
}

export default MovieDisplay;
